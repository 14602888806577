import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg from '../../assets/images/holidayphoto.png'

const Banner = () => {
    return (
        <div className="contact-area pb-100">
            <div className="container">
                <containerarticles>
                    <article styleName="--slist: #ebac79,#d65b56">
                        <h3>gingerbread</h3>
                        <p>Pancake muffin chocolate syrup brownie.</p>
                    </article>
                    <article styleName="--slist: #90cbb7,#2fb1a9">
                        <h3>brownie</h3>
                        <p>Cake lemon berry muffin plum macaron.</p>
                    </article>
                    <article styleName="--slist: #8a7876,#32201c">
                        <h3>ice cream</h3>
                        <p>Wafer apple tart pie muffin gingerbread.</p>
                    </article>
                    <article styleName="--slist: #a6c869,#37a65a">
                        <h3>lava cake</h3>
                        <p>Liquorice plum topping chocolate lemon.</p>
                    </article> 
                </containerarticles>
            </div>
        </div>
    )
}

export default Banner