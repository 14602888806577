import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg from '../../assets/images/holidayphoto.png'

const Banner = () => {
    return (
        <div className="contact-area pt-100 pb-100">
            <div className="container pt-100">
                <div className="banner1">
                    <div className="shopping-image">
                        <img src="https://images.unsplash.com/photo-1534452203293-494d7ddbf7e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80" alt="shopping"/>
                    </div>
                    
                    <div className="text">
                        <h4>
                            HOLIDAY SUPERSALE
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner