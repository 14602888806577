import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg from '../../assets/images/holidayphoto.png'

const Banner = () => {
    return (
        <div className="contact-area pb-100">
            <div className="container">
                <containerpeple>
                    <p styleName="--slist: #ebac79,#d65b56">Muhammad Nawaz Hussain</p>
                    <p styleName="--slist: #90cbb7,#2fb1a9">Kashif Iqbal Manj</p>
                    <p styleName="--slist: #8a7876,#32201c">Usman Ali</p>
                    <p styleName="--slist: #a6c869,#37a65a">Amir Saeed</p>
                </containerpeple>
            </div>
        </div>
    )
}

export default Banner