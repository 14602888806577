import React from 'react'
import Layout from "../components/App/Layout"
import NavbarNoButton from "../components/App/NavbarNoButton" 
import Banner1 from "../components/Demo/Banner1"
import Banner2 from "../components/Demo/Banner2"
import Banner3 from "../components/Demo/Banner3"
import Footer from "../components/App/Footer"  
const Contact = () => {
    return (
        <Layout>
            <NavbarNoButton />
            <Banner1 />
            <Banner2 /> 
            <Banner3 /> 
            <Footer />
        </Layout> 
    );
}

export default Contact